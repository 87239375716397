<template>
    <div class="customer-detail-container">

    </div>
</template>

<script>
    export default {
        name: "customer-detail",
        props: ['data'],
        data() {
            return {
                info: this.data
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
